<template>
  <div>
    <div v-if="!showDetail">
      <div
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 3px solid #1d74e0;
        "
      >
        <div class="title">过户记录</div>
        <div class="btn search" @click="exportInDepotInfo">导出</div>
      </div>

      <div class="block">
        <el-form ref="form" :model="form" size="small">
          <el-row>
            <el-col :span="4">
              <el-form-item
                label="合同编号："
                label-width="100px"
                prop="contractNumber"
              >
                <el-input v-model="form.contractNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item
                label="交易对手："
                label-width="100px"
                prop="userName"
              >
                <el-input v-model="form.userName"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="5">
              <el-form-item label="过户状态：" label-width="100px" prop="status">
                <el-select v-model="form.status" placeholder="请选择" >
                  <el-option v-for="item in options" :key="item.code" :label="item.value" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="7">
              <el-form-item label="过户日期：" label-width="100px" prop="date">
                <el-date-picker
                  v-model="form.date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="width: 240px"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <div style="display: flex">
                  <div class="btn search" @click="search">查询</div>
                  <div class="btn reset" @click="reset">重置</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="table">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          :cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333',
          }"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in tableHeader"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
            <template slot-scope="scope">
              <div v-if="item.prop === 'status'">
                {{ getStatusName(scope.row.status) }}
              </div>
              <div v-else-if="item.prop !== 'operate'">
                {{ scope.row[item.prop] }}
              </div>
              <div v-else>
                <el-button
                  type="text"
                  size="mini"
                  @click="downloadFile(scope.row.transferOrderId, 'download')"
                >
                  下载
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  @click="downloadFile(scope.row.transferOrderId, 'print')"
                >
                  打印
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          :total="total"
          :page-sizes="[50, 100, 200, 300, 500]"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          style="margin-top: 10px"
        ></el-pagination>
      </div>
    </div>
    <div v-if="showDetail">
      <div class="title">仓单过户单明细</div>
      <div class="c-title">过户信息</div>
      <div class="info">
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">过户单号：</div>
            <div class="value">{{ transferOrderNumber }}</div>
          </div>
          <div class="form-item">
            <div class="label">卖家：</div>
            <div class="value">{{ sellerName }}</div>
          </div>
        </div>
        <div class="info-block">
          <div class="form-item bottom">
            <div class="label">买家：</div>
            <div class="value">{{ buyerName }}</div>
          </div>
          <div class="form-item">
            <div class="label">过户仓库：</div>
            <div class="value">{{ warehouseName }}</div>
          </div>
        </div>
      </div>
      <div class="c-title">过户明细</div>
      <el-table :data="warehouseReceipts" style="width: 100%">
        <el-table-column
          prop="commodityName"
          label="品名/品牌"
          align="center"
          width="560"
        >
          <template slot-scope="scope"
            >{{ scope.row.commodityName + "/" + scope.row.productorName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="billingWeight"
          label="开单重量"
          align="center"
          width="360"
        >
        </el-table-column>
        <el-table-column prop="transferNumber" label="过户件数" align="center">
        </el-table-column>
        <el-table-column prop="transferWeight" label="过户重量" align="center">
        </el-table-column>
      </el-table>

      <div class="back-btn" @click="goback">返回</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import printJS from "print-js";

const http = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
  },
});
export default {
  name: "transferList",
  data() {
    return {
      form: {
        contractNumber: "",
        transferNo: "",
        userName: "",
        beginBillingDate: "",
        endBillingDate: "",
        date: [],
      },
      options: [
        {
          code: "1",
          value: "正常",
        },
        {
          code: "2",
          value: "已注销",
        },
      ],
      tableData: [],
      tableHeader: [],
      showDetail: false,

      total: 0,

      currentPage: 0,
      pageSize: 50,

      formLabelWidth: "100px",

      showCommodityContainer: false,
      showCommoditylist: false,

      buyerName: "",
      sellerName: "",
      transferOrderNumber: "",
      warehouseName: "",
      warehouseReceipts: [],
      multipleSelection: [],
    };
  },
  created() {
    if (this.$route.query.transferNo) {
      this.form.transferNo = this.$route.query.transferNo;
    }
    this.search();
    this.tableHeader = [
      {
        prop: "transferNo",
        label: "过户单编号",
      },
      {
        prop: "contractNumber",
        label: "合同编号",
      },
      {
        prop: "contractType",
        label: "合同类型",
      },
      {
        prop: "userName",
        label: "交易对手",
      },
      {
        prop: "billingDate",
        label: "申请时间",
      },
      {
        prop: "billingWeight",
        label: "开单重量（吨）",
      },
      {
        prop: "warehouseName",
        label: "仓库",
      },
      {
        prop: "receiptNO",
        label: "仓单编号",
      },
      {
        prop: "transferWeight",
        label: "过户重量（吨）",
      },
      {
        prop: "transferDate",
        label: "过户时间",
      },
      {
        prop: "number",
        label: "件数",
      },
      {
        prop: "cardNO",
        label: "原卡号",
      },
      {
        prop: "newCardNO",
        label: "下游卡号",
      },
      {
        prop: "operate",
        label: "操作",
      },
    ];
  },
  methods: {
    indexMethod(index) {
      index = index + 1 + this.currentPage * this.pageSize;
      return index;
    },
    // 获取选择内容
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getStatusName(status) {
      let name = "";
      this.options.forEach((item) => {
        if (status === item.code) {
          name = item.value;
        }
      });
      return name;
    },
    search() {
      let params = {
        process_id: "00000253",
        execution_type: "get",
        nextProcessNode: "001",
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: this.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.currentPage, // 分页页码（从0开始）
          contractNumber: this.form.contractNumber,
          transferNo: this.form.transferNo,
          userName: this.form.userName,
          beginBillingDate: this.form.date[0] || "", // 开单日期（起）
          endBillingDate: this.form.date[1] || "", // 开单日期（止）
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          this.tableData = res.data.content;
          this.total = res.data.total;
        })
        .catch((err) => {});
    },
    reset() {
      this.form = {
        contractNumber: "",
        transferNo: "",
        userName: "",
        beginBillingDate: "",
        endBillingDate: "",
        date: [],
      };
      this.search();
    },
    cancelOperate(ids) {
      let params = {
        process_id: "00000252",
        execution_type: "get",
        nextProcessNode: "002",
        nodeInfo: {
          data: ids, // 过户单ID
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          if (res.succeed === 0) {
            this.$message.success("注销成功");
            this.search();
          } else {
            this.$message.error(res.errorMsg);
          }
        })
        .catch((err) => {});
    },
    goback() {
      this.showDetail = false;
    },
    handleCurrentChange(page) {
      this.currentPage = page - 1;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 0;
      this.search();
    },
    //导出入库明细
    exportInDepotInfo() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择数据进行操作！",
          type: "warning",
        });
      } else if (this.multipleSelection.length > 500) {
        this.$message({
          message: "建议单次导出不超过500条",
          type: "warning",
        });
      } else {
        let wopts = {
          bookType: "xlsx",
          bookSST: true,
          type: "binary",
        };
        let workBook = {
          SheetNames: ["Sheet1"],
          Sheets: {},
          Props: {},
        };
        let table = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let params = {};
          params = {
            过户单编号: this.multipleSelection[i].transferOrderId,
            合同编号: this.multipleSelection[i].contractNumber,
            合同类型: this.multipleSelection[i].contractType,
            交易对手: this.multipleSelection[i].userName,
            申请时间: this.multipleSelection[i].billingDate,
            "开单重量（吨）": this.multipleSelection[i].billingWeight,
            仓库: this.multipleSelection[i].warehouseName,
            仓单编号: this.multipleSelection[i].receiptNO,
            "过户重量（吨）": this.multipleSelection[i].transferWeight,
            过户时间: this.multipleSelection[i].transferDate,
            件数: this.multipleSelection[i].number,
            卡号: this.multipleSelection[i].cardNO,
          };

          table[i] = params;
        }
        console.log(2222, FileSaver);
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets["Sheet1"] = XLSX.utils.json_to_sheet(table);

        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        let nameExc = "";
        nameExc = "过户记录导出";

        FileSaver.saveAs(
          new Blob([this.changeData(XLSX.write(workBook, wopts))], {
            type: "application/octet-stream",
          }),
          nameExc + this.formatterDate() + ".xlsx"
        );
      }
    },

    formatterDate() {
      var date = new Date();
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // 拼接
      return year + "年" + month + "月" + day + "日";
    },
    changeData(s) {
      //如果存在ArrayBuffer对象(es6) 最好采用该对象
      if (typeof ArrayBuffer !== "undefined") {
        //1、创建一个字节长度为s.length的内存区域
        let buf = new ArrayBuffer(s.length);

        //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
        let view = new Uint8Array(buf);

        //3、返回指定位置的字符的Unicode编码
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      } else {
        let buf = new Array(s.length);
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
    },
    downloadFile(transferOrderId, type) {
      if (!transferOrderId) {
        this.$message.error("未找到文件!");
        return;
      }
      let params = {
        process_id: "00000252",
        execution_type: "get",
        nextProcessNode: "005",
        nodeInfo: {
          transferOrderId: transferOrderId,
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          this.showFile(res.data, type);
        })
        .catch((err) => {});
    },
    base64ToBlob(code, suffix) {
      debugger;
      code = code.replace(/[\n\r]/g, "");
      // atob() 方法用于解码使用 base-64 编码的字符串。
      const raw = window.atob(code);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      console.log(3311, uInt8Array);
      return new Blob([uInt8Array], { type: `application/${suffix}` });
    },

    showFile(sBaseFile, type) {
      if (!sBaseFile) {
        ///文件不存在！
      }
      const blob = this.base64ToBlob(sBaseFile, "pdf");
      console.log(31, blob);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const fileURL = URL.createObjectURL(blob);
        if (type === "print") {
          printJS({
            printable: fileURL,
            type: "pdf",
          });
        } else {
          const aLink = document.createElement("a");
          aLink.style.display = "none";
          aLink.href = fileURL;
          aLink.setAttribute("download", "申报单.pdf"); // 下载的文件
          document.body.appendChild(aLink);
          aLink.click();
          document.body.removeChild(aLink);
        }
      }
    },
    printFile(rowData) {
      if (!rowData) {
        this.$message.error("未找到文件!");
        return;
      }
      http
        .get("/file/getFilePath?fileId=" + rowData)
        .then((res) => {
          const { data } = res.data;
          let url = data["common_file@path"];
          // printJS(`/downfile${url}`)
          let printURL = `/downfile${data["common_file@path"]}`;
          // printURL = decodeURI(encodeURI(printURL))
          // printJS(printURL)
          fetch(printURL)
            .then((response) => response.blob())
            .then((res) => {
              let blob = new Blob([res], { type: "application/pdf" });
              // pdfurl即转化后的结果
              let pdfurl = window.URL.createObjectURL(blob);
              // 新标签页打开，即可预览并下载
              window.open(pdfurl);
            });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
}
.block {
  border: #e5e5e5 1px solid;
  padding: 10px 30px 0 30px;
  margin-top: 5px;
}
.export-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}
.table {
  margin-top: 10px;
}
.c-title {
  color: #717071;
  font-size: 14px;
  height: 35px;
  margin: 10px auto;
  line-height: 35px;
  border-bottom: 1px dashed #e3e3e3;
}
.info {
  display: flex;
  padding: 10px 0 10px 100px;
  .info-block {
    flex-basis: 400px;
    .form-item {
      display: flex;
      align-items: center;
      .label {
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #666666;
      }
      .value {
        color: #666666;
        font-size: 14px;
      }
      &.bottom {
        margin-bottom: 20px;
      }
    }
  }
}
.back-btn {
  background: #2d5171;
  color: #ffffff;
  font-size: 12px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  width: 80px;
  margin-top: 2px;
  text-align: center;
  cursor: pointer;
}
.container {
  position: absolute;
  z-index: 99;
  width: 480px;
  border: 1px solid rgb(47, 116, 149);
  background: #fff;
  .head {
    background: #67a1e2;
    color: #fff;
    font-size: 9pt;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .content {
    height: 180px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .content-item {
    width: 100px;
    margin-left: 10px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .footer {
    display: flex;
    height: 24px;
    background: rgb(200, 227, 252);
    .common {
      width: 40px;
      &:hover {
        background: rgb(232, 244, 255);
        cursor: pointer;
      }
    }
    .key-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .key {
        width: 100%;
        text-align: center;
        &:hover {
          background: rgb(232, 244, 255);
          cursor: pointer;
          text-align: center;
        }
      }
    }
    div {
      line-height: 24px;
    }
  }
}
.list {
  position: absolute;
  z-index: 9999;
  width: 180px;
  border: 1px solid #2f7495;
  background: #fff;
  height: 145px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: auto;
  li {
    color: #21407d;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      background: #c8e3fc;
    }
  }
}
</style>
